<template>
    
	<div class="cont" id="p20">
		<div class="page-title">결제 내역 조회</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('c'); search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('c'); search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
							<div class="notice">최대 3개월 이내에서 조회할 수 있습니다.</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">서비스</div>
						<div class="content">
							<select 
								v-model="search.payChnCate"
								@change="getData"
							>
								<option 
									v-for="(item, index) in payChnCate"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">결제</div>
						<div class="content">
							<select 
								v-model="search.messageType"
								@change="getData"
							>
								<option 
									v-for="(item, index) in messageType"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="영업점명 입력"
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
						
				</div>
			</div>
			<div class="right">
				<div class="group g04">
					<div class="item">
						<div class="tit">
							<img src="@/assets/img/icon-01.png" alt="">
							<div class="desc">결제건수</div>
						</div>
						<div class="content">
							<div class="amount"><span class="cnt">{{ search.total_count }}</span>건</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="c02">
			<div class="table type09">
				<div class="text-right mb-10">
					<Pagination 
						:options="search"
					/>
				</div>
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">승인번호</div>
						<div class="td">결제일</div>
						<div class="td">승인금액</div>
						<div class="td">취소금액</div>
						<div class="td">거래금액</div>
						<div class="td">수수료</div>
						<div class="td">수수료율</div>
						<div class="td">정산금액</div>
						<div class="td">정산예정</div>
						<div class="td">AGENT</div>
						<div class="td">취소일자(원거래일자)</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.approvalNumber }}</div>
						<div class="td">{{ item.approvalDate | transDate }}</div>
						<div class="td">{{ item.approvalAmount | makeComma }}원</div>
						<div class="td">{{ item.cancelAmount | makeComma }}원</div>
						<div class="td">{{ item.approvalAmount | makeComma }}원</div>
						<div class="td">{{ item.feeAmount | makeComma }}원</div>
						<div class="td">{{ item.feeRate }}%</div>
						<div class="td">{{ item.settlementAmount |makeComma }}원</div>
						<div class="td">{{ item.settlementDate | transDate }}</div>
						<div class="td">{{ item.agentInformation }}</div>
						<div class="td">{{ item.cancelDate | transDate }}</div>
					</div>
					<div
						v-show="!items.length"
					>
						<div class="no-data">No Data</div>
					</div>
				</div>
				<div class="text-right mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>

		<div class="popup-list"
			v-if="false"
		>
			<div class="popup type02">
				<div class="p-header red">
					<div class="title">결제 수수료 정산 지급 불가</div>
					<div class="btn-close_popup">닫기</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-header"><span class="title">지급불가</span></div>
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">지급불가일</div>
									<div class="desc">2020.11.15</div>
								</div>
								<div class="item">
									<div class="title">사유</div>
									<div class="desc">사유입니다.</div>
								</div>
							</div>
							<div class="p-text">
								상세 사유가 궁금하신 경우 고객센터에 문의주세요.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user']
		,components: { Pagination }
		,data: function(){
			return {
				program: {
					name: '가맹점 정산 내역'
				}
				,items: []
				,excel_items: []
				,search_default: {
					inquiryStartDate: '20200101'
					,inquiryEndDate: ''
					,keyword: ''
					,messageType: ''
					,payChnCate: ''
					,list_cnt: 20
				}
				,search: {
				}
				,dateType: 'c'
				,today: date.getToday()
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,payChnCate: [
					{ code: '', codeName: '서비스 구분 선택', value: 0, use: false}
					,{ code: '001', codeName: '인증(온라인)', value: 0, use: false}
					,{ code: '002', codeName: '비인증(수기)', value: 0, use: false }
					,{ code: '003', codeName: 'OFF PG(단말기)', value: 0, use: false }
				]
				,messageType: [
					{ code: '', codeName: '결제 구분 선택', value: 0, use: false}
					,{ code: 'Normal', codeName: '승인', value: 0, use: false}
					,{ code: 'Cancel', codeName: '취소', value: 0, use: false }
				]
				,columns: [
					{ label: 'No', field: 'rowNumber'}
					,{ label: '승인번호', field: 'approvalNumber'}
					,{ label: '결제일', field: 'approvalDate'}
					,{ label: '승인금액', field: 'approvalAmount'}
					,{ label: '취소금액', field: 'cancelAmount'}
					,{ label: '거래금액', field: 'tradAmount'}
					,{ label: '수수료', field: 'feeAmount'}
					,{ label: '수수료율', field: 'feeRate'}
					,{ label: '정산금액', field: 'settlementAmount'}
					,{ label: '정산예정', field: 'settlementDate'}
					,{ label: 'AGENT', field: 'agentInformation'}
					,{ label: '취소일자(원거래일자)', field: 'cancelDate'}
				]
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				const search = this.search
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/history'
						,data: search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,setSearchDate: async function(type){
				this.dateType = type
				if(type == 'd'){
					this.search.inquiryStartDate = date.getToday()
					this.search.inquiryEndDate = date.getToday()
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}
				
				await this.getData()
			}
			,clear: function(){
				this.search = {
					inquiryStartDate: '20200101'
					,inquiryEndDate: ''
					,keyword: ''
					,messageType: ''
					,payChnCate: ''
					,list_cnt: 20
				}
				this.setSearchDate('c')
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/history/download'
						,data: {
							inquiryStartDate: this.search.inquiryStartDate
						}
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: async function(){
			this.clear()
			await this.getData()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>























